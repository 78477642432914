import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { RouteReusableStrategy } from './route-reusable-strategy';
import { AuthenticationService } from './authentication/authentication.service';
import {
  AuthenticationGuard,
  AgentAdminAuthenticationGuard,
  AdminAuthenticationGuard,
  UserAuthenticationGuard,
  AgentAdminUserAuthenticationGuard
} from './authentication/authentication.guard';
import { I18nService } from './i18n.service';
import { HttpService } from './http/http.service';
import { HttpCacheService } from './http/http-cache.service';
import { ApiPrefixInterceptor } from './http/api-prefix.interceptor';
import { ErrorHandlerInterceptor } from './http/error-handler.interceptor';
import { CacheInterceptor } from './http/cache.interceptor';
import { HttpHeaderInterceptor } from './http/http-header.interceptor';
import { NotificationService } from './notification.service';
import { WebSocketService } from '@app/shared/chat/web-socket.service';

@NgModule({
  imports: [CommonModule, HttpClientModule, TranslateModule, RouterModule],
  providers: [
    AuthenticationService,
    AuthenticationGuard,
    AgentAdminAuthenticationGuard,
    AdminAuthenticationGuard,
    UserAuthenticationGuard,
    AgentAdminUserAuthenticationGuard,
    I18nService,
    HttpCacheService,
    ApiPrefixInterceptor,
    HttpHeaderInterceptor,
    ErrorHandlerInterceptor,
    WebSocketService,
    NotificationService,
    CacheInterceptor,
    {
      provide: HttpClient,
      useClass: HttpService
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy
    }
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
