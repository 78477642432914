import { Routes, Route } from '@angular/router';

import { AuthenticationGuard, AgentAdminUserAuthenticationGuard } from '@app/core';
import { ShellComponent } from './shell.component';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { WebSocketService } from '@app/shared/chat/web-socket.service';
import { MatSnackBar } from '@angular/material';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';

/**
 * Provides helper methods to create routes.
 */
export class Shell {
  /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @return {Route} The new route using shell as the base.
   */
  static childRoutes(routes: Routes): Route {
    return {
      path: '',
      component: ShellComponent,
      children: routes,
      canActivate: [AuthenticationGuard, AgentAdminUserAuthenticationGuard],
      // Reuse ShellComponent instance when navigating between child views
      data: { reuse: true }
    };
  }
}
