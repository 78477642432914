import { Injectable } from '@angular/core';
import { WebSocketService } from '@app/shared/chat/web-socket.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { environment } from '@env/environment';
import { Subject } from 'rxjs';
import { AuthenticationService } from './authentication/authentication.service';
import { map } from 'rxjs/operators';
export interface WSNotification {
  message: string;
}

@Injectable()
export class NotificationService {
  public notificationWS: Subject<WSNotification>;
  public opensnackBar$: Subject<Number> = new Subject();

  constructor(
    private authenticationService: AuthenticationService,
    private wsService: WebSocketService,
    private snackBar: MatSnackBar
  ) {}

  initWSNotification() {
    this.notificationWS = <Subject<WSNotification>>(
      this.wsService.connect(environment.wsURL + 'notifications/' + this.authenticationService.credentials.id).pipe(
        map(
          (response: MessageEvent): WSNotification => {
            const data: WSNotification = JSON.parse(response.data);
            return data;
          }
        )
      )
    );

    this.notificationWS.subscribe((res: WSNotification) => {
      if (res.message) {
        if (document.location.href.includes('/#/chat-bot') && res.message.includes('You have no new conversation.')) {
          return;
        }
        const data = res.message;
        this.openSnackBar(data, 'Dismiss');
      }
    });
  }

  sendNotificationStatus() {
    this.wsService.sendNotificationMessage();
  }
  disconnectNotification() {
    this.wsService.disconnect();
  }

  private openSnackBar(message: string, action: string) {
    const config = new MatSnackBarConfig();
    // config.duration = 10000;
    config.horizontalPosition = 'end';
    config.verticalPosition = 'top';
    const snackBarRef = this.snackBar.open(message, 'Dismiss', config);
    if (!message.includes('You have no new conversation.') && !message.includes('has ended the chat.')) {
      setTimeout(() => {
        this.opensnackBar$.next(1);
      }, 2000);
    }
    snackBarRef.afterDismissed().subscribe(() => {
      this.opensnackBar$.next(0);
    });
  }
}
