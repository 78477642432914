import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { AdminAuthenticationGuard } from './core';

const routes: Routes = [
  Shell.childRoutes([
    { path: '', redirectTo: '/user-login', pathMatch: 'full' },
    {
      path: 'about',
      loadChildren: 'app/about/about.module#AboutModule'
    },
    {
      path: 'agent-management',
      loadChildren: 'app/agent-management/agent-management.module#AgentManagementModule'
    },
    {
      path: 'escalation-queue',
      loadChildren: 'app/escalation-queue/escalation-queue.module#EscalationQueueModule'
    },
    {
      path: 'home',
      loadChildren: 'app/home/home.module#HomeModule'
    },
    {
      path: 'chat-bot',
      loadChildren: 'app/chat-bot/chat-bot.module#ChatBotModule'
    },
    {
      path: 'upload-csv',
      loadChildren: 'app/upload-csv/upload-csv.module#UploadCsvModule'
    }
  ]),
  {
    path: 'chat',
    loadChildren: 'app/user-chat/user-chat.module#UserChatModule'
  },
  {
    path: 'login',
    loadChildren: 'app/login/login.module#LoginModule'
  },
  {
    path: 'user-login',
    loadChildren: 'app/user-login/user-login.module#UserLoginModule'
  },
  {
    path: 'fake-homepage',
    loadChildren: 'app/fake-homepage/fake-homepage.module#FakeHomepageModule'
  },
  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true
      // enableTracing: true // For debug purpose.
    })
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
