import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { Logger } from '../logger.service';
import { AuthenticationService } from './authentication.service';

/**
 * Logger instance.
 */
const log = new Logger('AuthenticationGuard');

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthenticationService) {}
  canActivate(): boolean {
    if (this.authenticationService.isAuthenticated()) {
      return true;
    }

    // log.debug('Not authenticated, redirecting...');
    if (document.location.href.includes('/#/chat-bot') || document.location.href.includes('/#/user-login')) {
      this.router.navigate(['/user-login'], { replaceUrl: true });
    } else {
      this.router.navigate(['/login'], { replaceUrl: true });
    }
    return false;
  }
}

@Injectable()
export class AgentAdminAuthenticationGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthenticationService) {}
  canActivate(): boolean {
    const role_group_id = [0, 1];
    if (role_group_id.indexOf(this.authenticationService.credentials.role_kind) > -1) {
      return true;
    }
    this.router.navigate(['/login'], { replaceUrl: true });
    return false;
  }
}

@Injectable()
export class AdminAuthenticationGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthenticationService) {}
  canActivate(): boolean {
    const role_group_id = [0];
    if (role_group_id.indexOf(this.authenticationService.credentials.role_kind) >= 0) {
      return true;
    }
    this.router.navigate(['/login'], { replaceUrl: true });
    return false;
  }
}

@Injectable()
export class UserAuthenticationGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthenticationService) {}
  canActivate(): boolean {
    const role_group_id = [2];
    if (role_group_id.indexOf(this.authenticationService.credentials.role_kind) >= 0) {
      return true;
    }
    this.router.navigate(['/user-login'], { replaceUrl: true });
    return false;
  }
}

@Injectable()
export class AgentAdminUserAuthenticationGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthenticationService) {}
  canActivate(): boolean {
    const role_group_id = [0, 1, 2];
    if (role_group_id.indexOf(this.authenticationService.credentials.role_kind) > -1) {
      return true;
    }
    this.router.navigate(['/login'], { replaceUrl: true });
    return false;
  }
}
