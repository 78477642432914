import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

/**
 * A route strategy allowing for explicit route reuse.
 * Used as a workaround for https://github.com/angular/angular/issues/18374
 * To reuse a given route, add `data: { reuse: true }` to the route definition.
 */
export class RouteReusableStrategy extends RouteReuseStrategy {
  /**
   * Determines if this route (and its subtree) should be detached to be reused later
   *
   * @param {ActivatedRouteSnapshot} route
   * @returns {boolean}
   * @memberof RouteReusableStrategy
   */
  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  /**
   * Stores the detached route.
   * Storing a null value should erase the previously stored value.
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {(DetachedRouteHandle | null)} detachedTree
   * @memberof RouteReusableStrategy
   */
  public store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle | null): void {}

  /**
   * Determines if this route (and its subtree) should be reattached
   *
   * @param {ActivatedRouteSnapshot} route
   * @returns {boolean}
   * @memberof RouteReusableStrategy
   */
  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  /**
   * Determines if this route (and its subtree) should be reattached
   *
   * @param {ActivatedRouteSnapshot} route
   * @returns {(DetachedRouteHandle | null)}
   * @memberof RouteReusableStrategy
   */
  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }

  /**
   * Determines if this route (and its subtree) should be reattached
   *
   * @param {ActivatedRouteSnapshot} future
   * @param {ActivatedRouteSnapshot} curr
   * @returns {boolean}
   * @memberof RouteReusableStrategy
   */
  public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig || future.data.reuse;
  }
}
