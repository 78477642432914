import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@app/material.module';

import { ShellComponent } from './shell.component';
import { ConfirmDialogComponent } from '@app/shared/dialogs/confirm-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonDialogComponent } from '@app/shared/common-dialog/common-dialog.component';

@NgModule({
  imports: [CommonModule, TranslateModule, FlexLayoutModule, MaterialModule, RouterModule, ReactiveFormsModule],
  declarations: [ShellComponent, ConfirmDialogComponent, CommonDialogComponent],
  entryComponents: [ConfirmDialogComponent, CommonDialogComponent]
})
export class ShellModule {}
