import { Title } from '@angular/platform-browser';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ObservableMedia } from '@angular/flex-layout';

import { AuthenticationService, I18nService } from '@app/core';
import { NotificationService } from '@app/core/notification.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ConfirmDialogComponent } from '@app/shared/dialogs/confirm-dialog.component';
import { Subscription } from 'rxjs';
import { environment } from '@env/environment';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit, OnDestroy {
  currentAgentRoleKind: number;
  currentAgentId: number;
  selectedAbout: boolean;
  audio = new Audio();
  subscription: Subscription;
  /**
   * Creates an instance of ShellComponent.
   * @param {Router} router
   * @param {Title} titleService
   * @param {ObservableMedia} media
   * @param {AuthenticationService} authenticationService
   * @param {I18nService} i18nService
   * @memberof ShellComponent
   */
  constructor(
    private router: Router,
    private titleService: Title,
    private media: ObservableMedia,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private i18nService: I18nService,
    private dialog: MatDialog
  ) {
    this.currentAgentRoleKind = 5;
    this.currentAgentId = this.authenticationService.credentials.id;
    this.currentAgentRoleKind = this.authenticationService.credentials.role_kind;
    this.selectedAbout = false;
  }

  /**
   * Update language.
   *
   * @param {string} language
   * @memberof ShellComponent
   */
  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  ngOnInit() {
    this.notificationService.initWSNotification();

    setTimeout(() => {
      this.notificationService.sendNotificationStatus();
    }, 200);

    this.subscription = this.notificationService.opensnackBar$.subscribe(res => {
      if (res === 1) {
        if (this.audio != null) {
          this.audio.pause();
          this.audio = null;
        }
        this.audio = new Audio();
        this.audio.src = `${environment.notificationAudioUrl}/audio.wav`;
        this.audio.loop = true;
        this.audio.load();
        this.audio.play();
      } else {
        if (this.audio != null) {
          this.audio.pause();
          this.audio = null;
        }
      }
    });
  }

  /**
   * Logout.
   *
   * @memberof ShellComponent
   */
  logout() {
    this.authenticationService
      .logout()
      .subscribe(() =>
        this.currentAgentRoleKind === 2
          ? this.router.navigate(['/user-login'], { replaceUrl: true })
          : this.router.navigate(['/login'], { replaceUrl: true })
      );
  }

  /**
   * Getter of user.
   *
   * @readonly
   * @type {(string | null)}
   * @memberof ShellComponent
   */
  get username(): string | null {
    const credentials = this.authenticationService.credentials;
    return credentials ? credentials.username : null;
  }

  /**
   * Getter of user first name.
   *
   * @readonly
   * @type {(string | null)}
   * @memberof ShellComponent
   */
  get firstname(): string | null {
    const credentials = this.authenticationService.credentials;
    return credentials ? credentials.firstname : null;
  }

  /**
   * Getter of supported language.
   *
   * @readonly
   * @type {string[]}
   * @memberof ShellComponent
   */
  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  /**
   * Getter to check is mobile screen size.
   *
   * @readonly
   * @type {boolean}
   * @memberof ShellComponent
   */
  get isMobile(): boolean {
    return this.media.isActive('xs') || this.media.isActive('sm');
  }

  /**
   * Getter to get current title.
   *
   * @readonly
   * @type {string}
   * @memberof ShellComponent
   */
  get title(): string {
    return this.titleService.getTitle();
  }

  openAgentProfile() {
    this.router.navigate(['/agent-management/' + this.currentAgentId], { replaceUrl: true });
  }

  ngOnDestroy() {
    this.notificationService.disconnectNotification();
    this.subscription.unsubscribe();
  }

  /**
   * Check and go to about page
   *
   * @memberof ShellComponent
   */
  goToAbout() {
    if (document.location.href.includes('/#/chat-bot')) {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.position = {
        top: '20px'
      };
      dialogConfig.maxWidth = '100vw';
      const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(data => {
        if (data) {
          this.selectedAbout = true;
          this.router.navigate(['/about'], { replaceUrl: true });
        }
      });
    } else {
      this.selectedAbout = true;
      this.router.navigate(['/about'], { replaceUrl: true });
    }
  }

  reNewChat(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.position = {
      top: '20px'
    };
    dialogConfig.maxWidth = '100vw';
    const confirmData = {
      description: 'Are you sure you want to refresh the chat session?'
    };
    dialogConfig.data = confirmData;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/chat-bot']);
        // window.location.reload();
      }
    });
  }
}
