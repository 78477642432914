// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --dev` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// `.env.ts` is generated by the `npm run env` command
import env from './.env';

/**
 * Setup dev environment settings.
 * Support SSL certificate and domain name
 */
export const environment = {
  production: false,
  version: env.npm_package_version + '-prod',
  wsURL: 'wss://prod.ai-bot.cushwake.com/service/ws/conversations/',
  serverUrl: 'https://prod.ai-bot.cushwake.com/service',
  notificationAudioUrl: 'https://prod.ai-bot.cushwake.com/media/audio/',
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'fr-FR'],
  credentialsKey: 'credentials',
  siteKey: '6Le6zdMZAAAAAAmhYEGP5uWMjc3Snsxrp-DgqL4E'
};
