import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserRepositoryService {
  url: string;

  constructor(private httpService: HttpClient) {
    this.url = '/users';
  }

  login(username: string, pass: string): Observable<any> {
    const requestBody = {} as Object;
    requestBody['username'] = username;
    requestBody['password'] = pass;
    return this.httpService.post(this.url + '/login', requestBody);
  }

  userLogin(username: string, pass: string, captchaToken: string): Observable<any> {
    const requestBody = {} as Object;
    requestBody['username'] = username;
    requestBody['password'] = pass;
    requestBody['captchaToken'] = captchaToken;
    return this.httpService.post(this.url + '/user-login', requestBody);
  }

  authenCaptcha(captchaToken: string) {
    const result = this.httpService.get(this.url + '/captcha/' + captchaToken);
    return result;
  }

  changeUserStatus(userId: string, userStatus: string) {
    return this.httpService.put(this.url + '/' + userId + '/status/' + userStatus, {});
  }

  getById(id: string): Observable<any> {
    return this.httpService.get(this.url + '/' + id);
    // throw new Error('Method not implemented.');
  }

  patch(id: string, updateKey: string, updateValue: string, imageName: string) {
    let requestBody = {};
    if (updateKey !== 'picture') {
      requestBody[updateKey] = updateValue;
    } else {
      const png_str = updateValue.split(',')[1];
      const img_file = new File([png_str], imageName);
      const fg = new FormData();
      fg.append('picture', img_file);
      requestBody = fg;
    }
    return this.httpService.patch(this.url + '/' + id, requestBody);
  }

  changeConversationStatus(conversationId: string) {
    return this.httpService.put(`/conversations/${conversationId}/status/3`, {});
  }

  all(): Observable<any> {
    return this.httpService.get(this.url);
  }

  update(id: any, item: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
  delete(id: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
  add(item: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
}
