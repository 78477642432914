import { Injectable } from '@angular/core';
import { Subject, Observable, Observer } from 'rxjs';
import { Logger } from '@app/core/logger.service';

const log = new Logger('WebSocket');
export interface Message {
  sender_id: string;
  message: string;
}

@Injectable()
export class WebSocketService {
  private ws: any;
  private subject: Subject<MessageEvent>;
  private connectedUrl = '';
  constructor() {
    // console.log('create web socket');
  }

  public connect(url: string): Subject<MessageEvent> {
    this.subject = this.create(url);
    this.connectedUrl = url;
    // console.log('Successfullly connected: ' + url);
    return this.subject;
  }

  public disconnect() {
    if (this.ws) {
      // console.log('go to close websocket ' + this.connectedUrl);
      this.ws.close();
      this.ws = null;
      this.subject.unsubscribe();
    }
    // this.subject = null;
  }

  public sendMessage(message: string, sender_id: string) {
    const data: Message = { message: message, sender_id: sender_id };
    // log.debug(data);
    this.subject.next(<any>data);
  }

  public sendNotificationMessage() {
    const data = { status: 0 } as Object;
    // console.log(data);
    this.subject.next(<any>data);
  }

  public sendstatusMessage() {
    const data = { event: 'Scheduler' } as Object;
    this.subject.next(<any>data);
  }

  public sendDashBoardMessage() {
    const data = { data: 'get' } as Object;
    // console.log(data);
    this.subject.next(<any>data);
  }
  private create(url: string): Subject<MessageEvent> {
    this.ws = new WebSocket(url);

    const observable = Observable.create((obs: Observer<MessageEvent>) => {
      this.ws.onmessage = obs.next.bind(obs);
      this.ws.onerror = obs.error.bind(obs);
      this.ws.onclose = obs.complete.bind(obs);
      return this.ws.close.bind(this.ws);
    });

    const observer = {
      next: (data: Object) => {
        if (this.ws.readyState === WebSocket.OPEN) {
          this.ws.send(JSON.stringify(data));
        }
      }
    };

    return Subject.create(observer, observable);
  }
}
