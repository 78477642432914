import { Observable, of } from 'rxjs';

import { Credentials, LoginContext } from './authentication.service';

export class MockAuthenticationService {
  credentials: Credentials | null = {
    id: 2,
    role_kind: 1,
    username: 'john',
    firstname: 'John',
    lastname: 'FefF',
    service_type_ids: [],
    service_type_names: []
  };

  login(context: LoginContext): Observable<Credentials> {
    return of({
      id: 2,
      role_kind: 1,
      username: 'john',
      firstname: 'John',
      lastname: 'FefF',
      service_type_ids: [],
      service_type_names: []
    });
  }

  logout(): Observable<boolean> {
    this.credentials = null;
    return of(true);
  }

  isAuthenticated(): boolean {
    return !!this.credentials;
  }
}
