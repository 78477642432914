import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';

/**
 * Prefixes all requests with `environment.serverUrl`.
 */
@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let _credentials = null;
    const savedCredentials =
      sessionStorage.getItem(environment.credentialsKey) || localStorage.getItem(environment.credentialsKey);
    if (savedCredentials) {
      _credentials = JSON.parse(savedCredentials);
    }
    if (_credentials) {
      // console.log(_credentials);
      const authReq = request.clone({
        headers: request.headers
          .set('X-User-Id', _credentials.id + '')
          .set('Authorization', 'Token ' + _credentials.token + '')
      });
      return next.handle(authReq);
    } else {
      // console.log("Don't have header");
    }
    return next.handle(request);
  }
}
