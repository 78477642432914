import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';

import { environment } from '@env/environment';
import { UserRepositoryService } from '@app/repository/user-repository.service';
import { map } from 'rxjs/operators';
/**
 * Login response.
 * TODO: Redefine to fit backend response.
 *
 * @export
 * @interface Credentials
 */
export interface Credentials {
  id: number;
  role_kind: number;
  username: string;
  firstname: string;
  lastname: string;
  service_type_ids: number[];
  service_type_names: string[];
  token?: string | null;
}

/**
 * Login context model.
 *
 * @export
 * @interface LoginContext
 */
export interface LoginContext {
  /**
   * Username
   *
   * @type {string}
   * @memberof LoginContext
   */
  username: string;

  /**
   * Password
   *
   * @type {string}
   * @memberof LoginContext
   */
  password: string;

  /**
   * Remember login.
   *
   * @type {boolean}
   * @memberof LoginContext
   */
  remember?: boolean;
}

/**
 * Key determine 'credential' in cache/local storage.
 */

/**
 * Provides a base for authentication workflow.
 * The Credentials interface as well as login/logout methods should be replaced with proper implementation.
 */
@Injectable()
export class AuthenticationService {
  private _credentials: Credentials | null;

  constructor(private userRepositoryService: UserRepositoryService) {
    const savedCredentials = localStorage.getItem(environment.credentialsKey);
    // sessionStorage.getItem(environment.credentialsKey) || localStorage.getItem(environment.credentialsKey);
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
    }
  }

  /**
   * Authenticates the user.
   * @param {LoginContext} context The login parameters.
   * @return {Observable<Credentials>} The user credentials.
   */
  login(context: LoginContext): Observable<any> {
    return this.userRepositoryService.login(context.username, context.password).pipe(
      map((element: Response) => {
        if (element['status'] === 202) {
          this.setCredentials(element['data'], context.remember);
        }
        return element;
      })
    );
  }

  /**
   * Authenticates the user.
   * @param {LoginContext} context The login parameters.
   * @return {Observable<Credentials>} The user credentials.
   */
  userLogin(context: LoginContext, captchaToken: string): Observable<any> {
    return this.userRepositoryService.userLogin(context.username, context.password, captchaToken).pipe(
      map((element: Response) => {
        if (element['status'] === 202) {
          this.setCredentials(element['data'], context.remember);
        }
        return element;
      })
    );
  }

  changeUserStatus(userId: string, userStatus: string) {
    this.userRepositoryService.changeUserStatus(userId, userStatus);
  }
  /**
   * Logs out the user and clear credentials.
   * @return {Observable<boolean>} True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this.setCredentials();
    return of(true);
  }

  /**
   * Checks is the user is authenticated.
   * @return {boolean} True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !!this.credentials;
  }

  /**
   * Checks is the user is authenticated.
   * @return {boolean} True if the user is authenticated.
   */
  isAuthenticatedTest(): boolean {
    return !this.credentials;
  }

  /**
   * Gets the user credentials.
   * @return {Credentials} The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials | null {
    return this._credentials;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param {Credentials=} credentials The user credentials.
   * @param {boolean=} remember True to remember credentials across sessions.
   */
  private setCredentials(credentials?: Credentials, remember?: boolean) {
    // console.log(credentials);
    this._credentials = credentials || null;
    if (credentials) {
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem(environment.credentialsKey, JSON.stringify(credentials));
    } else {
      if (document.location.href.includes('/#/chat-bot')) {
        const conversationId = localStorage.getItem('conversationId');
        if (conversationId) {
          this.userRepositoryService.changeConversationStatus(conversationId).subscribe((res: Response) => {});
        }
      }
      sessionStorage.removeItem(environment.credentialsKey);
      localStorage.removeItem(environment.credentialsKey);
      localStorage.removeItem('conversationId');
    }
  }
}
